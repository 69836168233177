import * as Sentry from "@sentry/browser";

Sentry.onLoad(function() {
    const replay = new Sentry.Replay({
        networkDetailAllowUrls: [window.location.origin],
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
    });
    Sentry.init({
        dsn: "https://ea0a4a9c6b2d490586de1a429aa4bf3a@sentry.punktero.dev/53",
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.BrowserProfilingIntegration(),
            replay
        ],
        tracesSampleRate: 1.0,
        profilesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });

    replay.start();
    setInterval(() => {
        replay.flush();
    }, 5*1000);
});
